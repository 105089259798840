import { Accordion, AccordionButton, AccordionIcon, AccordionItem, Image, AccordionPanel, Box, Button, ButtonGroup, Card, CardBody, CardHeader, Editable, EditableInput, EditablePreview, Flex, FormControl, FormLabel, HStack, Heading, IconButton, Input, LinkBox, LinkOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, SkeletonText, Spacer, Spinner, Stack, StackDivider, Switch, Text, Tooltip, useEditableControls, useToast } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { Campaign } from '../models/campaign';
import { PageContentSummary } from '../models/pageContentSummary';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import SiteTitle from './siteTitle';
import { Constants } from '../constants';
import CreateContentPage from './createContentPage';
import FileDialog from './fileDialog';
import { CampaignTheme } from '../models/campaignTheme';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { colourResultToHex } from '../utils/rgbHex';
import useAuthentication from '../hooks/useAuthentication';

interface CampaignProps {
    campaignId: string;
}

const CampaignPage: FC<CampaignProps> = ({ campaignId }) => {
    const toast = useToast();
    const { jsonFetch, jsonAuthFetch } = useFetchWithMsal();
    const { isAuthenticated } = useAuthentication();

    const [campaignState, setCampaignState] = React.useState<Campaign | null>(null);
    const [isCampaignLoadedState, setIsCampaignLoadedState] = React.useState<boolean>(false);

    const [pageContentSummaryState, setPageContentSummaryState] = React.useState<PageContentSummary[] | null>(null);
    const [isPageContentSummaryLoadedState, setPageContentSummaryLoadedState] = React.useState<boolean>(false);

    // theme
    const [bannerEnabledState, setBannerEnabledState] = React.useState<boolean>(false);
    const [logoNameState, setLogoNameState] = React.useState<string>("");
    const [logoUrlState, setLogoUrlState] = React.useState<string>("");
    const [bannerColourState, setBannerColourState] = React.useState<string>("");
    const [bannerTextState, setBannerTextState] = React.useState<string>("");
    const [bannerTextColourState, setBannerTextColourState] = React.useState<string>("");
    const [backgroundState, setBackgroundState] = React.useState<string>("");
    const [backgroundUrlState, setBackgroundUrlState] = React.useState<string>("");
    const [backgroundImagePositionState, setBackgroundImagePositionState] = React.useState<string>("");
    const [backgroundImageOpacityState, setBackgroundImageOpacityState] = React.useState<number>(100);
    const [backgroundColourState, setBackgroundColourState] = React.useState<string>("");
    const [textColourState, setTextColourState] = React.useState<string>("");
    const [colourSchemeState, setColourSchemeState] = React.useState<string>("");

    // todo: work out what a reducer is
    const [nameState, setNameState] = React.useState<string>("");
    const [descriptionState, setDescriptionState] = React.useState<string>("");
    const [publiclyListedState, setPubliclyListedState] = React.useState<boolean>();

    function updateCampaignState(campaign: Campaign) {
        setCampaignState(campaign);
        setNameState(campaign?.name ?? "");
        setDescriptionState(campaign?.description ?? "");
        setIsCampaignLoadedState(true);
        setPubliclyListedState(campaign.publiclyListed === true)

        if (campaign.theme) {
            setBannerEnabledState(campaign.theme.enableMenu === true);
            setLogoNameState(campaign.theme.logoName ?? "");
            setLogoUrlState(campaign.theme.logoUrl ?? "");
            setBannerColourState(campaign.theme.bannerColour ?? "");
            setBannerTextState(campaign.theme.bannerText ?? "");
            setBannerTextColourState(campaign.theme.bannerTextColour ?? "");
            setBackgroundState(campaign.theme.backgroundName ?? "");
            setBackgroundUrlState(campaign.theme.backgroundUrl ?? "");
            setBackgroundColourState(campaign.theme.backgroundColour ?? "");
            setBackgroundImagePositionState(campaign.theme.backgroundImagePosition ?? Constants.backgroundImagePositionCentered)
            setBackgroundImageOpacityState(campaign.theme.backgroundImageOpacity ?? 100);
            setTextColourState(campaign.theme.textColour ?? "");
            setColourSchemeState(campaign.theme.colourScheme ?? "");
        }
    }

    const fetchPageData = useCallback((campaignId: string) => {
        jsonFetch(`${Constants.url}/content-pages/campaign/${campaignId}`)
            .then(data => {
                setPageContentSummaryState(data);
                setPageContentSummaryLoadedState(true);
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching page data.',
                    description: `Reason: ${reason}`
                });
            });
    }, [jsonFetch, toast]);

    const fetchCampaignData = useCallback((campaignId: string) => {
        jsonFetch(`${Constants.url}/campaign/${campaignId}`)
            .then(data => {
                updateCampaignState(data);
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching campaign data.',
                    description: `Reason: ${reason}`
                });
            });
    }, [jsonFetch, toast]);

    const updateCampaign = (changes: any) => {
        const clone: Campaign = { ...campaignState! };
        const updated = Object.assign(clone, changes);

        setIsCampaignLoadedState(false);

        jsonAuthFetch(`${Constants.url}/create-campaign`, updated)
            .then(data => {
                updateCampaignState(data);
            }).catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching campaign data.',
                    description: `Reason: ${reason}`
                });
            });
    };

    const updateStartPage = (pageId: string) => {
        jsonAuthFetch(`${Constants.url}/update-campaign-start-page`, { pageContentId: `${pageId}`, campaignId: `${campaignId}` })
            .then(data => {
                updateCampaignState(data);
                toast({
                    status: 'success',
                    title: 'Start page update successful.'
                });
            }).catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error updating start page.',
                    description: `Reason: ${reason}`
                });
            });
    }

    const updateTheme = () => {
        let backgroundImageOpacity = backgroundImageOpacityState;
        if (isNaN(backgroundImageOpacity)) {
            backgroundImageOpacity = 100;
        }
        const theme: CampaignTheme = {
            enableMenu: bannerEnabledState,
            logoName: logoNameState,
            bannerColour: bannerColourState,
            bannerText: bannerTextState,
            bannerTextColour: bannerTextColourState,
            backgroundName: backgroundState,
            backgroundColour: backgroundColourState,
            backgroundImagePosition: backgroundImagePositionState,
            backgroundImageOpacity: backgroundImageOpacity,
            textColour: textColourState,
            colourScheme: colourSchemeState
        };
        jsonAuthFetch(`${Constants.url}/update-campaign-theme/${campaignId}`, theme)
            .then(_response => {
                fetchCampaignData(campaignId);
                toast({
                    status: 'success',
                    title: 'Theme update successful.'
                });
            }).catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error updating theme.',
                    description: `Reason: ${reason}`
                });
            });
    }

    useEffect(() => {
        fetchCampaignData(campaignId);
        fetchPageData(campaignId);
    }, [fetchCampaignData, fetchPageData, campaignId]);

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()

        return isEditing ? (
            <ButtonGroup justifyContent='center' size='sm'>
                <IconButton aria-label='submit' icon={<CheckIcon />} {...getSubmitButtonProps()} />
                <IconButton aria-label='close' icon={<CloseIcon />} {...getCancelButtonProps()} />
            </ButtonGroup>
        ) : (
            <IconButton aria-label='edit' size='xs' verticalAlign='super' margin={1} icon={<EditIcon />} {...getEditButtonProps()} />
        )
    }

    return (
        <>
            {isAuthenticated ?
                <Box width="100%" padding={2}>
                    <SiteTitle />

                    <Heading size='xs'>Campaign {campaignId}</Heading>
                    <SkeletonText hidden={isCampaignLoadedState} isLoaded={isCampaignLoadedState} mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />

                    <Editable
                        value={nameState}
                        isPreviewFocusable={false}
                        onChange={setNameState}
                        onSubmit={e => updateCampaign({ name: e })}
                        onCancel={_ => setNameState(campaignState?.name ?? "")}
                    >
                        <Heading as={EditablePreview}>{campaignState?.name}</Heading>
                        <Input as={EditableInput} />
                        <EditableControls />
                    </Editable>

                    <Card>
                        <CardHeader>
                            <Heading size='md'>Details</Heading>
                        </CardHeader>
                        <CardBody >

                            <SkeletonText hidden={isCampaignLoadedState} isLoaded={isPageContentSummaryLoadedState} mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                            <Stack hidden={!isCampaignLoadedState} divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Heading size='sm' textTransform='uppercase'>
                                        Description
                                    </Heading>
                                    <Editable
                                        value={descriptionState}
                                        isPreviewFocusable={false}
                                        onChange={setDescriptionState}
                                        onSubmit={e => updateCampaign({ description: e })}
                                        onCancel={_ => setDescriptionState(campaignState?.description ?? "")}
                                    >
                                        <Text as={EditablePreview}>{campaignState?.description}</Text>
                                        <Input as={EditableInput} />
                                        <EditableControls />
                                    </Editable>
                                </Box>
                                <Box>
                                    <Heading size='sm' textTransform='uppercase'>
                                        Publicly listed
                                    </Heading>
                                    <Switch
                                        size='sm'
                                        isChecked={publiclyListedState}
                                        onChange={e => updateCampaign({ publiclyListed: e.target.checked })}
                                         />
                                </Box>
                                <Box>
                                    <Heading size='sm' textTransform='uppercase'>
                                        Start Page
                                    </Heading>
                                    <Text>{campaignState?.startPageId ?? "There is no start page. Set one below."}</Text>
                                </Box>
                                <Box>
                                    <Heading size='sm' textTransform='uppercase'>
                                        Campaign Style
                                    </Heading>
                                    <Text mb='4'>
                                        Below are options to change presentation settings for all pages in this campaign.
                                    </Text>
                                    <Text mb='4'>
                                        <b>Press Save Style for a settings to take effect.</b> Pages already opened will need to be refreshed to see new changes.
                                    </Text>
                                    <Accordion allowMultiple>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Enable banner
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Toggles if the top banner should appear on pages.</Text>
                                                <FormControl display='flex' alignItems='center'>
                                                    <FormLabel htmlFor='menu-enabled' mb='0'>
                                                        Enable banner:
                                                    </FormLabel>
                                                    <Switch id='menu-enabled' isChecked={bannerEnabledState} onChange={(e) => { setBannerEnabledState(e.target.checked); }} />
                                                </FormControl>
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Banner text
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Sets the text to appear within a banner. This text will appear on every page.</Text>
                                                <Editable
                                                    value={bannerTextState}
                                                    isPreviewFocusable={false}
                                                    onChange={setBannerTextState}
                                                    onSubmit={e => setBannerTextState(e)}
                                                    onCancel={_ => setBannerTextState(campaignState?.theme?.bannerText ?? "")}
                                                >
                                                    <Text as={EditablePreview}>{campaignState?.theme?.bannerText ?? ""}</Text>
                                                    <Input as={EditableInput} />
                                                    <EditableControls />
                                                </Editable>
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Banner icon
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Sets the icon to appear in a banner that appears on every page. The icon should be small, it is restricted to 3 times the current font size.</Text>
                                                {logoUrlState ? <Image height='3em' src={logoUrlState} /> : <></>}
                                                <Text>{logoNameState ?? "None"}</Text>
                                                <FileDialog onSubmit={(r) => { setLogoNameState(r?.name ?? ""); setLogoUrlState(r?.url ?? ""); }} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Banner colour
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>The colour of banner.</Text>
                                                <SketchPicker color={bannerColourState} onChangeComplete={(c) => { setBannerColourState(colourResultToHex(c)); }} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Banner text colour
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>The colour of text in the banner. Choose a colour that constrasts with the banner colour.</Text>
                                                <SketchPicker color={bannerTextColourState} onChangeComplete={(c) => { setBannerTextColourState(colourResultToHex(c)); }} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Background colour
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Sets the background colour of every page in the campaign.
                                                    This colour will show through transparent areas of any background image that is used.</Text>
                                                <SketchPicker color={backgroundColourState} onChangeComplete={(c) => { setBackgroundColourState(colourResultToHex(c)); }} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Background image
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Sets the image that is the background of every page in the campaign.
                                                    The image can be a repeating pattern or a singular picture; see the background image position option.</Text>
                                                {backgroundUrlState ? <Image maxWidth='100%' src={backgroundUrlState} /> : <></>}
                                                <FileDialog onSubmit={(r) => { setBackgroundState(r?.name ?? ""); setBackgroundUrlState(r?.url ?? ""); }} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Background image position
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Sets how the background image is positioned.
                                                    Use Tiled of the background image is a repeating pattern.
                                                    Use Centered if the image is a standalone picture, it will be centered and resized to the user's screen.</Text>
                                                <Select value={backgroundImagePositionState} onChange={(e) => { setBackgroundImagePositionState(e.target.value) }}>
                                                    <option value={Constants.backgroundImagePositionTiled}>Tiled</option>
                                                    <option value={Constants.backgroundImagePositionCentered}>Centered</option>
                                                </Select>
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Background image opacity
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Opacity of the background image as a percentage. 100% is a solid image, 0% is completely see through.
                                                    Set below 100 to have the background colour show through the image.
                                                </Text>
                                                <NumberInput
                                                    maxW={24}
                                                    min={0}
                                                    max={100}
                                                    step={5}
                                                    value={backgroundImageOpacityState}
                                                    onChange={(e) => { setBackgroundImageOpacityState(parseInt(e, 10)) }}>
                                                    <NumberInputField />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Text colour
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Colour of the text. Some components, (eg, buttons) are not modified by this setting.</Text>
                                                <SketchPicker color={textColourState} onChangeComplete={(c) => { setTextColourState(colourResultToHex(c)); }} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Colour scheme
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text mb='4'>Sets the colour scheme of complicated user interface components (eg, buttons).
                                                </Text>
                                                <Select value={colourSchemeState} onChange={(e) => { setColourSchemeState(e.target.value) }}>
                                                    <option value=''>Normal</option>
                                                    <option value='gray'>Gray</option>
                                                    <option value='red'>Red</option>
                                                    <option value='orange'>Orange</option>
                                                    <option value='yellow'>Yellow</option>
                                                    <option value='green'>Green</option>
                                                    <option value='teal'>Teal</option>
                                                    <option value='blue'>Blue</option>
                                                    <option value='cyan'>Cyan</option>
                                                    <option value='purple'>Purple</option>
                                                    <option value='pink'>Pink</option>
                                                </Select>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                    <Button mt='4' colorScheme='blue' onClick={_ => { updateTheme() }}>Save Style</Button>
                                </Box>
                            </Stack>
                        </CardBody>
                    </Card>

                    <Box padding={2} />

                    <Card>
                        <CardHeader>
                            <Flex minWidth='max-content' alignItems='center' gap='2'>
                                <Box p='2'>
                                    <Heading size='md'>Pages</Heading>
                                </Box>
                                <Spacer />
                                <ButtonGroup gap='2'>
                                    <CreateContentPage campaignId={campaignId} />
                                </ButtonGroup>
                            </Flex>
                        </CardHeader>
                        <CardBody >

                            <SkeletonText hidden={isPageContentSummaryLoadedState} isLoaded={isPageContentSummaryLoadedState} mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                            <Stack divider={<StackDivider />} spacing='4'>
                                {pageContentSummaryState?.filter(campaign => campaign.id).map(page => {
                                    return <Box key={page.id}>
                                        <Heading size='xs' textTransform='uppercase' mb={3}>
                                            {page?.id}
                                        </Heading>

                                        <HStack spacing='4'>

                                            <LinkBox>
                                                <LinkOverlay href={`/page/${campaignId}/${page.id}`}>
                                                    <Button>
                                                        View
                                                    </Button>
                                                </LinkOverlay>
                                            </LinkBox>
                                            <LinkBox>
                                                <LinkOverlay href={`/page/${campaignId}/${page.id}/edit`}>
                                                    <Button>
                                                        Edit
                                                    </Button>
                                                </LinkOverlay>
                                            </LinkBox>

                                            <Box>
                                                <Spinner hidden={isCampaignLoadedState} size='sm' />

                                                <Tooltip isDisabled={isCampaignLoadedState && campaignState?.startPageId !== page.id} label="This page is already the start page.">
                                                    <Button isDisabled={isCampaignLoadedState && campaignState?.startPageId === page.id}
                                                        type="button"
                                                        onClick={() => {
                                                            updateStartPage(page.id);
                                                        }}
                                                    >
                                                        Set as start page
                                                    </Button>
                                                </Tooltip>
                                            </Box>
                                        </HStack>
                                    </Box>
                                })}
                            </Stack>
                        </CardBody>
                    </Card >
                </Box>
                : <></>
            }
        </>
    );
};

export default CampaignPage;
