import { LinkBox, LinkOverlay, Heading, Text, Box } from "@chakra-ui/react";
import { FC } from "react";

const SiteTitle: FC = () => {

    return (
        <>
            <LinkBox>
                <LinkOverlay href={`/`}>
                    <Heading size='2xl'>Vallery</Heading>
                    <Text fontSize='sm' as='i'>The virtual gallery</Text>
                </LinkOverlay>
            </LinkBox>
            <Box padding={4} />
        </>
    )
};

export default SiteTitle;
