import { Box, Button, HStack, VStack, useToast, Text, Alert, AlertDescription, AlertIcon, AlertTitle, Flex, Container, Spacer, Divider, Heading, Center, Spinner, Image } from '@chakra-ui/react';
import React, { FC, useEffect, useCallback, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSave } from "@react-icons/all-files/ai/AiOutlineSave";
import { Constants } from '../constants';
import NewComponentPrompt from './pageParts/newComponentPrompt';
import DeleteComponentPrompt from './pageParts/deleteComponentPrompt';
import UpArrowIcon from './icons/upArrow';
import DownArrowIcon from './icons/downArrow';
import { CampaignTheme } from '../models/campaignTheme';
import { useLocalStorage } from '../localStorage';
import PartSelector from './pageParts/partSelector';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import useAuthentication from '../hooks/useAuthentication';

interface ContentPageProps {
  pageId: string;
  campaignId: string;
  editMode: boolean;
}

interface ContentPageState {
  id?: string;
  title?: string;
  campaignId?: string;
  pageElements: any[];
}

export interface ContentPageContext {
  /**
   * The amount of extra padding that should be applied to the bottom of a page. Useful for floating elements.
   */
  bottomPaddingPx?: number
}

const ContentPage: FC<ContentPageProps> = ({ pageId, campaignId, editMode }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthentication();

  const [activePageId, setActivePageId] = React.useState<string>(pageId);
  const [activeCampaignId, setActiveCampaignId] = React.useState<string>(campaignId);
  const [pageState, setPageState] = React.useState<ContentPageState | null>(null);
  const [isLoadedState, setIsLoadedState] = React.useState<boolean>(false);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const [theme, setTheme] = useLocalStorage<CampaignTheme>(`theme-${campaignId}`, {});

  let contentPageContexts = React.useRef<{ [id: string]: ContentPageContext }>({});
  const [bottomPadding, setBottomPadding] = React.useState<number>(0);

  const { jsonFetch, jsonAuthFetch } = useFetchWithMsal();

  const fetchPageContent = useCallback((campaignId: string, activePageId: string) => {
    jsonFetch(`${Constants.url}/content-page/${campaignId}/${activePageId}`)
      .then(data => {

        if (!data.options) {
          data.options = [];
        }

        setPageState(data);
        setIsLoadedState(true);

        setActiveCampaignId(data.campaignId);
      })
      .catch(reason => {
        console.error(reason);
        toast({
          status: 'error',
          title: 'Error fetching page data.',
          description: `Reason: ${reason}`
        });
      });
  }, [jsonFetch, toast]);

  const savePageContent = () => {
    setIsLoadedState(false);

    let submission = { ...pageState };
    if (!submission?.id) {
      submission.campaignId = campaignId;
    }

    jsonAuthFetch(`${Constants.url}/create-page-content`, submission)
      .then(data => {
        // might be new page, update page id
        setActivePageId(data.id);

        setPageState(data);
        setIsLoadedState(true);
        setIsDirty(false);
      })
      .catch(reason => {
        console.error(reason);
        toast({
          status: 'error',
          title: 'Error saving page data.',
          description: `Reason: ${reason}`
        });
      });
  };

  const fetchCampaignData = useCallback((campaignId: string) => {
    jsonFetch(`${Constants.url}/campaign/${campaignId}`)
      .then(data => {
        const newTheme: CampaignTheme = data?.theme;
        console.log("got theme");
        console.log(newTheme);
        if (newTheme && newTheme?.hash !== theme?.hash) {
          console.log("saving theme");
          setTheme(newTheme);
          console.log("theme saved");
        }
      })
      .catch(reason => {
        console.error(reason);
        toast({
          status: 'error',
          title: 'Error fetching campaign theme.',
          description: `Reason: ${reason}`
        });
      });
  }, [jsonFetch, toast, theme, setTheme]);

  useEffect(() => {
    if (pageId) {
      setActivePageId(pageId);
    }
  }, [pageId]);

  useEffect(() => {
    setIsLoadedState(false);
    fetchPageContent(campaignId, activePageId);
    fetchCampaignData(campaignId);
    window.scrollTo(0, 0);
  }, [activePageId, campaignId, editMode, fetchPageContent, fetchCampaignData]);

  useLayoutEffect(() => {
    const bottomPadding = Object.entries(contentPageContexts.current)
      .map(([_, contentPageContext]) => contentPageContext.bottomPaddingPx ?? 0)
      .reduce((sum, current) => sum + current, 0);
    setBottomPadding(bottomPadding);
  }, [pageState]);

  const updatePageElement = (pageElementState: any, pageElementIndex: number) => {
    let clone: ContentPageState = { ...pageState! };
    clone.pageElements![pageElementIndex] = pageElementState;
    setPageState(clone);
    setIsDirty(true);
  };

  const createPageElement = (pageElementState: any) => {
    let clone: ContentPageState = { ...pageState! };
    clone.pageElements!.push(pageElementState);
    setPageState(clone);
    setIsDirty(true);
  };

  const deletePageElement = (pageElementIndex: number) => {
    let clone: ContentPageState = { ...pageState! };
    clone.pageElements!.splice(pageElementIndex, 1);
    setPageState(clone);
    setIsDirty(true);
  };
  return (
    <Box width="100%">
      {!editMode ? <>
        <Box w="100vw" h='100vh' position='fixed' left='0px' right='0px' top='0px' bottom='0px'
          backgroundColor={!editMode ? theme.backgroundColour : ''}
          zIndex={-2} />
        <Box w="100vw" h='100vh' position='fixed' left='0px' right='0px' top='0px' bottom='0px'
          backgroundImage={theme?.backgroundUrl}
          backgroundSize={theme?.backgroundImagePosition === Constants.backgroundImagePositionCentered ? 'contain' : ''}
          backgroundRepeat={theme?.backgroundImagePosition === Constants.backgroundImagePositionCentered ? 'no-repeat' : ''}
          backgroundPosition={theme?.backgroundImagePosition === Constants.backgroundImagePositionCentered ? 'center' : ''}
          opacity={`${theme?.backgroundImageOpacity ?? 100}%`}
          zIndex={-1} />
      </> : <></>}

      <Flex as="header" position="fixed" w="100%" backgroundColor="white" zIndex={200} padding='0' >
        <VStack>
          {isAuthenticated ?
            <HStack spacing='4' h='4em' w="100%">
              <Box w="100vw" h='4em' position='fixed' left='0px' right='0px' top='0px' backgroundColor='white'></Box>
              {editMode ?
                <Button variant='ghost' isDisabled={!activePageId} onClick={() => navigate(`/page/${activeCampaignId}/${activePageId}`)}>
                  View
                </Button>
                :
                <Button variant='ghost' onClick={() => navigate(`/page/${activeCampaignId}/${activePageId}/edit`)}>
                  Edit
                </Button>
              }
              {editMode ?
                <Button colorScheme='' variant='ghost' onClick={() => navigate(`/campaign/${activeCampaignId}/edit`)}>
                  Campaign
                </Button>
                :
                <></>
              }
              {isDirty ?
                <Alert status='warning' w='md'>
                  <AlertIcon />
                  <AlertTitle>There are unsaved changes.</AlertTitle>
                  <AlertDescription>

                    {editMode ?
                      <Button colorScheme='blue' rightIcon={<AiOutlineSave />} onClick={_ => savePageContent()}>
                        Save
                      </Button>
                      :
                      <></>
                    }
                  </AlertDescription>
                </Alert>
                :
                <></>
              }
            </HStack>
            : <></>
          }
          {!editMode && theme.enableMenu ?
            <Box textColor={theme.bannerTextColour} marginTop='0px !important'> {/* stack applies a 0.5rem margin-top that is difficult to remove */}
              <Box w="100vw" h='4em' position='fixed' top={isAuthenticated ? '4em' : ''} left='0px' right='0px' backgroundColor={theme.bannerColour}></Box>
              <HStack position='fixed' spacing='4' h='4em' w="100%" zIndex={250} padding='0'>
                {theme.logoUrl ? <Image height='3em' src={theme.logoUrl} /> : <></>}
                <Text>{theme.bannerText}</Text>
              </HStack>
            </Box>
            :
            <></>
          }
        </VStack>
      </Flex>

      <Container as="main" maxWidth='100%' textColor={!editMode ? theme?.textColour : ''} >
        {/* empty boxes to stop top content disappearing under header */}
        {isAuthenticated ?
          <Box h='4em' />
          : <></>}
        {!editMode && theme?.enableMenu ?
          <Box h='4em' />
          : <></>}

        {isLoadedState ?
          <VStack spacing={12} w="100%">
            <Box />
            {pageState?.pageElements.map((pageElement, pageElementIndex) => {
              // One might think all this could be refactored into a local function, maybe to clean up code
              // and reuse key logic. One would be wrong, rerender (which happens with every keystroke) causes
              // text entry to be lost. Messing with keys props does nothing.

              return <React.Fragment key={pageElementIndex}>
                {editMode && isAuthenticated ? <Box w='100%'>
                  <Divider marginTop='2em' border='1px solid' />
                  <Heading marginTop='2em' size='md'>Component type: {pageElement?.type}</Heading>
                  <Flex>
                    <Center>
                      <Heading size='sm'>Order</Heading>
                    </Center>

                    {/* Order up button */}
                    <Button isDisabled={pageElementIndex === 0} variant={'ghost'} leftIcon={<UpArrowIcon />} onClick={() => {
                      let pageElementsClone = [...pageState?.pageElements!];
                      const row = pageElementsClone.splice(pageElementIndex, 1)[0];
                      pageElementsClone.splice(pageElementIndex - 1, 0, row);
                      let clone: ContentPageState = { ...pageState! };
                      clone.pageElements = pageElementsClone;
                      setPageState(clone);
                      setIsDirty(true);
                      setPageState(clone);
                    }} ></Button>

                    {/* Order down button */}
                    <Button isDisabled={pageElementIndex === (pageState?.pageElements?.length ?? 0) - 1} variant={'ghost'} leftIcon={<DownArrowIcon />} onClick={() => {
                      let pageElementsClone = [...pageState?.pageElements!];
                      const row = pageElementsClone.splice(pageElementIndex, 1)[0];
                      pageElementsClone.splice(pageElementIndex + 1, 0, row);
                      let clone: ContentPageState = { ...pageState! };
                      clone.pageElements = pageElementsClone;
                      setPageState(clone);
                      setIsDirty(true);
                      setPageState(clone);
                    }}></Button>
                    <Spacer />

                    <DeleteComponentPrompt onDelete={() => deletePageElement(pageElementIndex)} />
                  </Flex>
                </Box>
                  : <></>}
                <PartSelector
                  pageElementIndex={pageElementIndex}
                  editMode={editMode && isAuthenticated}
                  theme={theme} pageId={activePageId}
                  campaignId={activeCampaignId}
                  pageElement={pageElement}
                  updatePageElement={updatePageElement}
                  updateContentPageContext={(context) => {
                    contentPageContexts.current[pageElementIndex] = context;
                  }} />
              </React.Fragment>

            })}
            <Box h={`${bottomPadding}px`} />
          </VStack> : <Center>
            <Box h='12em' />
            <Spinner size='xl' />
          </Center>}

        {editMode ? <>
          <Flex justifyContent='right' paddingTop='2em' paddingBottom='2em' >
            <NewComponentPrompt editMode={editMode && isAuthenticated} onUpdate={(state) => createPageElement(state)} />
          </Flex>
        </> : <></>}


      </Container>
    </Box>
  );
};

export default ContentPage;
