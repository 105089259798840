import { Box, Button, Card, CardBody, CardHeader, Flex, HStack, Heading, LinkBox, LinkOverlay, SkeletonText, Spacer, Stack, StackDivider, Text, useToast } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import { Campaign } from '../models/campaign';
import CreateCampaign from './createCampaign';
import SiteTitle from './siteTitle';
import { Constants } from '../constants';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import Login from './login';
import useAuthentication from '../hooks/useAuthentication';

const Home: FC = () => {
    const toast = useToast();

    const { isAuthenticated } = useAuthentication();

    const [campaignState, setCampaignState] = React.useState<Campaign[]>([]);
    const [isLoadedState, setIsLoadedState] = React.useState<boolean>(false);

    const { jsonFetch, jsonAuthFetch } = useFetchWithMsal();

    useEffect(() => {
        const fetchPageData = () => {
            const campaignFetch = isAuthenticated ? jsonAuthFetch : jsonFetch;
            campaignFetch(`${Constants.url}/campaigns`)
                .then(data => {
                    setCampaignState(data ?? []);
                    setIsLoadedState(true);
                })
                .catch(reason => {
                    console.error(reason);
                    toast({
                        status: 'error',
                        title: 'Error fetching campaigns.',
                        description: `Reason: ${reason}`
                    });
                });
        };
        fetchPageData();
    }, [jsonFetch, jsonAuthFetch, toast, isAuthenticated]);

    return (
        <>

            <Flex as="header" pointerEvents='none' position="fixed" w="100%" h='4em' zIndex={1000} padding={2} >
                <Spacer />
                <Box pointerEvents='auto'>
                    <Login />
                </Box>
            </Flex>

            <Box width="100%" padding={2}>
                <SiteTitle />
                <Card>
                    <CardHeader>
                        <Flex minWidth='max-content' alignItems='center' gap='2'>
                            <Box p='2'>
                                <Heading size='md'>Campaigns</Heading>
                            </Box>
                            <Spacer />
                            {isAuthenticated ?
                                <CreateCampaign />
                                : <></>
                            }
                        </Flex>
                    </CardHeader>
                    <CardBody >
                        <SkeletonText hidden={isLoadedState} isLoaded={isLoadedState} mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                        <Stack divider={<StackDivider />} spacing='4'>
                            {campaignState?.filter(campaign => campaign.id)?.map(campaign => {
                                return <Box key={campaign.id}>
                                    <Heading size='xs' textTransform='uppercase'>
                                        {campaign?.name ?? campaign.id}
                                    </Heading>
                                    <Text pt='2' fontSize='x-small' fontStyle='italic'>
                                        ID: {campaign.id}
                                    </Text>
                                    <Text pt='2' fontSize='sm'>
                                        {campaign.description}
                                    </Text>
                                    <HStack spacing='4'>
                                        <LinkBox>
                                            <LinkOverlay href={`/campaign/${campaign.id}`}>
                                                <Button>
                                                    Start
                                                </Button>
                                            </LinkOverlay>
                                        </LinkBox>
                                        {isAuthenticated ?
                                            <LinkBox>
                                                <LinkOverlay href={`/campaign/${campaign.id}/edit`}>
                                                    <Button>
                                                        Edit
                                                    </Button>
                                                </LinkOverlay>
                                            </LinkBox>
                                            : <></>
                                        }
                                    </HStack>
                                </Box>
                            })}
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
        </>
    );
};

export default Home;
