import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Image, Heading, Text, Box, Textarea, Link, Input } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FC } from "react";
import { getImageSize } from "react-image-size";
import { ResourceSummary } from "../../models/resourceSummary";
import { CampaignTheme } from "../../models/campaignTheme";
import FileDialog from "../fileDialog";
import { PageElement, PageElementResource } from "../../models/pageElement";

interface PictureProps {
  editMode: boolean;
  state?: PageElement;
  theme?: CampaignTheme;
  onUpdate?: (updatedState: PageElement) => void;
}

const Picture: FC<PictureProps> = ({ editMode, state, onUpdate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fullImageResource, setFullImageResource] = React.useState<PageElementResource[]>(state?.resources ?? []);
  const [dimensions, setDimensions] = React.useState<string>("-");

  const updateUrl = (resourceSummary: ResourceSummary) => {
    const resourceUrl = resourceSummary?.url ?? '';
    let pageElementResource = fullImageResource?.[0] ?? {};
    pageElementResource.url = resourceUrl;
    setFullImageResource([pageElementResource]);
    onUpdate?.({ type: "image", resources: [pageElementResource] });
  }

  const updateAltText = (altText: string) => {
    let pageElementResource = fullImageResource?.[0] ?? {};
    pageElementResource.altText = altText;
    setFullImageResource([pageElementResource]);
    onUpdate?.({ type: "image", resources: [pageElementResource] });
  }

  const updateCaptionText = (captionText: string) => {
    let pageElementResource = fullImageResource?.[0] ?? {};
    pageElementResource.captionText = captionText;
    setFullImageResource([pageElementResource]);
    onUpdate?.({ type: "image", resources: [pageElementResource] });
  }

  const updateHeight = (height: string) => {
    let pageElementResource = fullImageResource?.[0] ?? {};
    pageElementResource.height = height;
    setFullImageResource([pageElementResource]);
    onUpdate?.({ type: "image", resources: [pageElementResource] });
  }

  useEffect(() => {
    const resourceUrl = fullImageResource?.[0]?.url ?? '';
    if (resourceUrl) {
      getImageSize(resourceUrl).then(resourceDimensions => {
        setDimensions(`${resourceDimensions.width} x ${resourceDimensions.height} pixels`)
      });
    }
  },
    [fullImageResource]);

  return (
    <Box w="100%">
      <Center>
        <Image
          border={editMode ? 'solid' : ''}
          maxHeight={fullImageResource?.[0]?.height ?? "400px"}
          fit={'scale-down'} src={fullImageResource?.[0]?.url}
          alt={fullImageResource?.[0]?.altText} onClick={onOpen} />
      </Center>

      {fullImageResource?.[0]?.captionText ?
        <Center>
          <Text
            fontSize="sm"
            as="i"
            whiteSpace="pre-wrap">{fullImageResource?.[0]?.captionText}</Text>
        </Center>
        : <></>}

      <Modal onClose={onClose} size='6xl' isOpen={isOpen}>
        <ModalOverlay bg='none'
          backdropFilter='auto'
          backdropInvert='80%'
          backdropBlur='2px' />
        <ModalContent>
          <ModalHeader>Close Up</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Box>
                <Image src={fullImageResource?.[0]?.url} />
                {fullImageResource?.[0]?.captionText ? <Text whiteSpace="pre-wrap">{fullImageResource?.[0]?.captionText}</Text> : <></>}
              </Box>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {editMode ?
        <>
          <FileDialog onSubmit={updateUrl} />
          <Text>Image dimensions: {dimensions}</Text>

          <Box textAlign={"left"} w="100%">
            <Heading size='sm'>Image height</Heading>
            <Text>
              The maximum height the image should be sized too. If the image is too large, it will be shrunk to size while maintaining aspect ratio. If no height is given, a default of 400 pixels will be used.
              The black border contains the padding that will be applied to the image and will not show in the real view.
            </Text>
            <Text>
              Any <Link href="https://developer.mozilla.org/en-US/docs/Web/CSS/height" target="_blank" color='blue.500'>height CSS value</Link> is supported, eg 600px for 600 pixels.
            </Text>
          </Box>
          <Input
            value={fullImageResource?.[0]?.height ?? ''}
            onChange={e => { updateHeight(e.target.value); }}
            placeholder='Image height'
            size='sm'
          />

          <Box textAlign={"left"} w="100%">
            <Heading size='sm'>Alt Text</Heading>
            <Text>
              Alternative (Alt) Text is meant to convey the "why" of the image as it relates to the content.
              It is read aloud to users by screen reader software, and  displays on the page if the image fails to load.
            </Text>
            <Text>
              Alt text can be several sentences long.
            </Text>
          </Box>
          <Textarea
            value={fullImageResource?.[0]?.altText ?? ''}
            onChange={e => { updateAltText(e.target.value); }}
            placeholder='Image alt text'
            size='sm'
          />

          <Box textAlign={"left"} w="100%">
            <Heading size='sm'>Caption Text</Heading>
            <Text>
              Smaller text displayed under an image.
            </Text>
          </Box>
          <Textarea
            value={fullImageResource?.[0]?.captionText ?? ''}
            onChange={e => { updateCaptionText(e.target.value); }}
            placeholder='Image caption text'
            size='sm'
          />
        </>
        :
        <></>
      }
    </Box>
  );
};

export default Picture;
