import { Box, Button, Modal, ModalOverlay, Image, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, useToast, Heading, Spinner, Center, Text, Table, Tbody, Td, Th, Thead, Tr, Grid, GridItem, VStack, Link, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { AiOutlineFileAdd } from "react-icons/ai";
import { Constants } from '../constants';
import { ResourceSummary } from '../models/resourceSummary';
import DirectoryIcon from './icons/directoryIcon';
import ImageIcon from './icons/imageIcon';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import AudioIcon from './icons/audioIcon';
import VideoIcon from './icons/videoIcon';

interface FileDialogProps {
    onSubmit?: (e: ResourceSummary) => void
}

const FileDialog: FC<FileDialogProps> = ({ onSubmit }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [currentDirectoryState, setCurrentDirectoryState] = useState<string>('');
    const [resourceList, setResourceList] = useState<ResourceSummary[] | null>(null);
    const [selectedResource, setSelectedResource] = useState<ResourceSummary | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const { jsonAuthFetch, formAuthFetch } = useFetchWithMsal();

    const resourceClicked = (resource: ResourceSummary) => {

        if (resource.isDirectory) {
            setCurrentDirectoryState(resource.name!);
        }
        else {
            setSelectedResource(resource);
        }
    };

    const iterCurrentDirectory = () => {
        let splits = `/${currentDirectoryState}`.split('/');

        let result = new Map<string, string>();
        let result2: { [key: string]: string } = {};
        let last = '';
        result2['home'] = last;
        for (let split of splits) {
            if (split) {
                last += `/${split}`;
                result.set(split, last);
                result2[split] = last;
            }
        }
        console.log('dirs from ' + currentDirectoryState);
        Object.entries(result2).map(([part, path]) => console.log(`    ${part} -> ${path}`));
        return result2;
    }

    const fetchResourceListData = useCallback((currentDirectoryState: string) => {
        jsonAuthFetch(`${Constants.url}/resource-list/?directory=${currentDirectoryState}`)
            .then(data => {
                setResourceList(data);
                setIsLoaded(true);
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching resource data.',
                    description: `Reason: ${reason}`
                });
            });
    }, [jsonAuthFetch, toast]);

    const uploadResource = (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        formAuthFetch(`${Constants.url}/upload-resource`, formData)
            .then((data) => {
                if (resourceList) {
                    let updatedResource = [...resourceList];
                    updatedResource.push(...data);
                    setResourceList(updatedResource);
                }
                toast({
                    status: 'success',
                    title: `Uploaded ${file.name}.`
                });
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error saving resource.',
                    description: `Reason: ${reason}`
                });
            });
    };

    const submitPressed = () => {
        if (selectedResource) {
            onSubmit?.(selectedResource);
        }
        onClose();
    }

    useEffect(() => {
        fetchResourceListData(currentDirectoryState);
    }, [currentDirectoryState, fetchResourceListData]);

    return (
        <Box>
            <Button mt='4' mb='4' colorScheme='blue' rightIcon={<AiOutlineFileAdd />} onClick={onOpen}>Resource Explorer</Button>

            <Modal size='6xl' isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Resource Explorer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Heading mb='2'>Current Resources</Heading>
                        <Box height='lg' mb='2'>
                            {isLoaded ?
                                <><HStack>
                                    <Box>
                                        Path:
                                    </Box>
                                    <Breadcrumb>
                                        {Object.entries(iterCurrentDirectory()).map(([part, path]) => {
                                            return <BreadcrumbItem>
                                                <BreadcrumbLink onClick={_ => setCurrentDirectoryState(path)}>{part ?? 'Home'}</BreadcrumbLink>
                                            </BreadcrumbItem>
                                        })}
                                    </Breadcrumb>
                                </HStack>
                                    <Tabs variant='soft-rounded' h='100%'>
                                        <TabList>
                                            <Tab>Grid</Tab>
                                            <Tab>List</Tab>
                                        </TabList>
                                        <TabPanels h='calc(100% - 2em)'>
                                            <TabPanel h='100%'>
                                                <SimpleGrid minChildWidth='120px' spacing='20px' overflow='auto' h='100%'>
                                                    {resourceList?.map(r => {
                                                        return <Box key={r.name} h='200px' onClick={_ => resourceClicked(r)} background={r?.name === selectedResource?.name ? 'Highlight' : ''} cursor={'pointer'} padding={4}>
                                                            <Box h='150px'>
                                                                <Center>
                                                                    {r?.isDirectory === true ?
                                                                        <DirectoryIcon size='100' /> : <></>
                                                                    }
                                                                    {r?.contentType?.startsWith('image/') ?
                                                                        <Image boxSize='100' h='100%' fit='scale-down' src={r.url} />
                                                                        : <></>
                                                                    }
                                                                    {r.contentType?.startsWith('audio/') ? <AudioIcon size='100' /> : <></>}
                                                                    {r.contentType?.startsWith('video/') ? <VideoIcon size='100' /> : <></>}
                                                                </Center>
                                                            </Box>
                                                            <Heading size='xs'>
                                                                {r.name}
                                                            </Heading>
                                                        </Box>
                                                    })}
                                                </SimpleGrid>
                                            </TabPanel>
                                            <TabPanel h='100%'>
                                                <Grid templateColumns='repeat(2, 1fr)' gap={2} h='100%'>
                                                    <GridItem w='100%' h='100%' overflow='auto'>
                                                        <Table variant='simple' size='sm'>
                                                            <Thead position="sticky" top={0} zIndex="docked">
                                                                <Tr backgroundColor='gray.100'>
                                                                    <Th></Th>
                                                                    <Th>Name</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {resourceList?.map(r => {
                                                                    return <Tr key={r.name} onClick={_ => resourceClicked(r)} background={r?.name === selectedResource?.name ? 'Highlight' : ''} cursor={'pointer'}>
                                                                        <Td>
                                                                            {r.isDirectory ? <DirectoryIcon /> : <></>}
                                                                            {r.contentType?.startsWith('image/') ? <ImageIcon /> : <></>}
                                                                            {r.contentType?.startsWith('audio/') ? <AudioIcon /> : <></>}
                                                                            {r.contentType?.startsWith('video/') ? <VideoIcon /> : <></>}
                                                                        </Td>
                                                                        <Td>{r.name}</Td>
                                                                    </Tr>
                                                                })}
                                                            </Tbody>
                                                        </Table>
                                                    </GridItem>
                                                    <GridItem w='100%'>
                                                        <Box h='100%'>
                                                            {selectedResource ?
                                                                <VStack>
                                                                    <Heading size='md'>
                                                                        {selectedResource.name}
                                                                    </Heading>
                                                                    <Link href={selectedResource.url} target='_blank'>Link</Link>
                                                                    {selectedResource?.contentType?.startsWith('image/') ?
                                                                        <Image h='220px' fit='scale-down' src={selectedResource.url} />
                                                                        : <></>
                                                                    }
                                                                    {selectedResource?.contentType?.startsWith('audio/') ?
                                                                        <audio controls>
                                                                            <source src={selectedResource.url} />
                                                                            This device cannot play audio.
                                                                        </audio>
                                                                        : <></>
                                                                    }
                                                                    {selectedResource?.contentType?.startsWith('video/') ?
                                                                        <video controls>
                                                                            <source src={selectedResource.url} />
                                                                            This device cannot play video.
                                                                        </video>
                                                                        : <></>
                                                                    }
                                                                    <Text>Content Type: {selectedResource.contentType}</Text>
                                                                    <Text>Content Size: {selectedResource.contentLength ? `${Math.round(selectedResource.contentLength / 1000)} kB` : "Unknown"}</Text>
                                                                    <Text>Created Date: {selectedResource.createdOn ? new Date(selectedResource.createdOn).toLocaleString() : "Unknown"}</Text>
                                                                </VStack>
                                                                :
                                                                <Center><Text>Select a resource for information.</Text></Center>
                                                            }
                                                        </Box>
                                                    </GridItem>
                                                </Grid>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </>
                                :
                                <Center>
                                    <Spinner size='xl' />
                                </Center>
                            }
                        </Box>
                        <Heading>Uploaded New Resource</Heading>
                        <Box>
                            <Dropzone onDrop={acceptedFiles => acceptedFiles.forEach(f => uploadResource(f))}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>Drag and drop some files here, or click to select files</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        {selectedResource ?
                            <Button colorScheme='blue' w='lg' onClick={submitPressed}>Select {selectedResource.name}</Button>
                            :
                            <Button colorScheme='blue' w='lg' disabled>Select a resource</Button>
                        }
                        <Button ml='4' variant='ghost' onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box >
    );
};

export default FileDialog;
